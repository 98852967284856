import { useQuery } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Card,
  CardContent,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import certificate from '../../../assets/qr/certificate.png';
import cprAid from '../../../assets/qr/cpr.png';
import nameTag from '../../../assets/qr/name-tag.png';
import startImg from '../../../assets/qr/start.jpg';
import waitImg from '../../../assets/qr/wait.png';
import { Flex } from '../../../base/components/Flex';
import FormikCheckBox from '../../../base/components/form/FormikCheckBox';
import FormikForm from '../../../base/components/form/FormikForm';
import FormikTextField from '../../../base/components/form/FormikTextField';
import {
  CompanyQrDocument,
  LaunchCompanyQrDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import i18n from '../../../i18n/i18n';
import { ClientLayout } from '../../../layouts';
import ClientLoginLogo from '../Login/ClientLoginLogo';
import QrSceneCard from './components/QrSceneCard';

type Step = 'name' | 'email' | 'scene' | 'launch' | 'wait' | 'done';

interface Wizzard {
  step: Step;
  nameForm: NameForm;
  emailForm: EmailForm;
  qrSceneId?: string;
}

interface NameForm {
  firstName: string;
  lastName: string;
  birthYear: string;
  gdpr: boolean;
}

interface EmailForm {
  email: string;
}
const LaunchCompanyQrPage = () => {
  const { id } = useParams<{ id: string }>();
  const [wizzard, setWizzard] = useState<Wizzard>({
    step: 'name',
    nameForm: { firstName: '', lastName: '', birthYear: '', gdpr: false },
    emailForm: { email: '' },
  });
  const { palette } = useTheme();
  const { _t } = useTranslationPrefix('LaunchPlaylistQrPage');

  const {
    data: qrData,
    refetch: qrRefetch,
    error: qrError,
  } = useQuery(CompanyQrDocument, {
    variables: {
      input: {
        id: id,
      },
    },
  });

  useQueryErrorSnackbar(qrError, qrRefetch);

  const navigateToDone = () => {
    setWizzard((w) => ({ ...w, step: 'done' }));
    setTimeout(() => {
      setWizzard((w) => ({ ...w, step: 'launch' }));
    }, 30 * 1000);
  };

  const navigateToWait = (seconds: number) => {
    setWizzard((w) => ({ ...w, step: 'wait' }));
    setTimeout(() => {
      navigateToDone();
    }, seconds * 1000);
  };

  const qrScenes = useMemo(() => {
    const age = wizzard.nameForm.birthYear
      ? new Date().getFullYear() - Number.parseInt(wizzard.nameForm.birthYear)
      : undefined;
    const qrScenes = qrData?.companyQr.qrScenes
      ?.filter((s) => !age || (age >= s.ageFrom && age <= s.ageTo))
      .map((s) => s);
    return qrScenes;
  }, [wizzard.nameForm.birthYear, qrData?.companyQr.qrScenes]);
  const hasMultipleScenes = !qrScenes?.length || qrScenes.length > 1;

  const [launchMutation, { loading: launching }] = useMutationWithSnackbar(
    'LaunchPlaylistQrPage.launchMutation',
    LaunchCompanyQrDocument,
  );

  const handleNamesSubmit = async (
    values: NameForm,
    helpers: FormikHelpers<NameForm>,
  ) => {
    setWizzard((w) => ({ ...w, step: 'email', nameForm: values }));
  };

  const handleEmailSubmit = async (
    values: EmailForm,
    helpers: FormikHelpers<EmailForm>,
  ) => {
    setWizzard((w) => ({
      ...w,
      step: hasMultipleScenes ? 'scene' : 'launch',
      emailForm: values,
    }));
  };

  const handleEmailskip = () => {
    setWizzard((w) => ({
      ...w,
      step: hasMultipleScenes ? 'scene' : 'launch',
      emailForm: { email: '' },
    }));
  };

  const handleSceneSelect = (qrSceneId: string) => () => {
    setWizzard((w) => ({
      ...w,
      qrSceneId: qrSceneId,
    }));
  };

  const handleSceneSelected = () => {
    setWizzard((w) => ({
      ...w,
      step: 'launch',
    }));
  };

  const handleLaunchClick = async () => {
    const qrSceneId = wizzard.qrSceneId || qrScenes![0]!.id;
    const scene = qrScenes?.find((s) => s.id === qrSceneId);
    const result = await launchMutation({
      variables: {
        input: {
          id: id!,
          firstName: wizzard.nameForm.firstName,
          lastName: wizzard.nameForm.lastName,
          email: wizzard.emailForm.email || null,
          languageCode: i18n.language,
          birthYear: Number.parseInt(wizzard.nameForm.birthYear),
          qrSceneId: qrSceneId,
        },
      },
    });
    if (!result.data?.launchCompanyQr) {
      return;
    }

    if (scene?.launchTimoutSeconds) {
      navigateToWait(scene.launchTimoutSeconds);
    } else {
      navigateToDone();
    }
  };

  return (
    <ClientLayout qrBackground={true}>
      <Flex flexDirection="column" alignItems="center" flexGrow={1}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth="80vw"
          width="100%"
          flexGrow={1}
        >
          <Card>
            <CardContent sx={{ pt: 3, pb: 4 }}>
              <ClientLoginLogo
                color={palette.text.secondary}
                sx={{ px: 8, pb: 5 }}
              />
              {wizzard.step === 'name' && (
                <Flex flexDirection="column" gap={3} alignItems="center">
                  {_t('enterNameFirst', 'Please enter your name first') && (
                    <Typography>
                      {_t('enterNameFirst', 'Please enter your name first')}
                    </Typography>
                  )}
                  <img src={nameTag} alt="Name tag" style={{ width: '20ch' }} />
                  <Formik<NameForm>
                    initialValues={wizzard.nameForm}
                    onSubmit={handleNamesSubmit}
                    validationSchema={getNameStepValidationSchema(_t)}
                  >
                    {({ isSubmitting, isValid, touched, errors }) => (
                      <FormikForm sx={{ margin: 0 }}>
                        <Flex flexDirection="column" gap={3}>
                          <FormikTextField
                            fullWidth
                            name="firstName"
                            variant="outlined"
                            label={_t('firstName', 'First name')}
                          />
                          <FormikTextField
                            fullWidth
                            name="lastName"
                            variant="outlined"
                            label={_t('lastName', 'Last name')}
                          />

                          <Flex position="relative">
                            <FormikTextField
                              fullWidth
                              name="birthYear"
                              variant="outlined"
                              label={_t('birthYear', 'Year of birth')}
                            />
                            <Tooltip
                              arrow={true}
                              placement="top"
                              enterTouchDelay={0}
                              leaveTouchDelay={3000}
                              componentsProps={{
                                tooltip: {
                                  sx: { maxWidth: '90vw' },
                                },
                              }}
                              title={
                                <Flex
                                  flexDirection="column"
                                  gap={1}
                                  maxHeight="60vh"
                                  overflow="auto"
                                >
                                  <Typography>
                                    {_t(
                                      'birthYearInfoP1',
                                      'We need to know your year of birth so we can run the appropriate scenario for you.',
                                    )}
                                  </Typography>
                                  <Typography>
                                    {_t(
                                      'birthYearInfoP2',
                                      'Children (born 2015 - 2010) learn significantly simpler first aid procedures or have reduced requirements (born 2011 - 2014). Youth and adults (born 2010 and younger) train in standard first aid.',
                                    )}
                                  </Typography>
                                </Flex>
                              }
                            >
                              <InfoIcon
                                color="primary"
                                sx={{
                                  fontSize: '1.5rem',
                                  position: 'absolute',
                                  right: 12,
                                  top: 15,
                                }}
                              />
                            </Tooltip>
                          </Flex>

                          <Flex alignItems="center" gap={1}>
                            <FormikCheckBox
                              name="gdpr"
                              label={_t('gdprAgreement', 'GDPR agreement')}
                            />
                            <Tooltip
                              arrow={true}
                              placement="top"
                              enterTouchDelay={0}
                              leaveTouchDelay={3000}
                              componentsProps={{
                                tooltip: {
                                  sx: { maxWidth: '90vw' },
                                },
                              }}
                              title={
                                <Flex
                                  flexDirection="column"
                                  gap={1}
                                  maxHeight="60vh"
                                  overflow="auto"
                                >
                                  <Typography>
                                    {_t(
                                      'gdprAgreementParagraph1',
                                      'By checking the box "GDPR Consent," I express my agreement with the processing of my personal data by VR Training s.r.o., registered at Karlovarská 451/70, 323 00, Plzeň, Company ID: 10995595, VAT ID: CZ10995595, as the data controller, for the purposes of registration and login to the VR Training web application.',
                                    )}
                                  </Typography>
                                  <Typography>
                                    {_t(
                                      'gdprAgreementParagraph2',
                                      'VR Training s.r.o. will process my personal data in accordance with Regulation (EU) 2016/679 of the European Parliament and the Council (GDPR) and will use this data solely for the purposes of providing services within this application, including user account management, ensuring security, and improving the user experience.',
                                    )}
                                  </Typography>
                                  <Typography>
                                    {_t(
                                      'gdprAgreementParagraph3',
                                      'I also agree that my data may be stored for the time necessary to provide these services. I have the right to withdraw my consent at any time by sending a request to support@vrtraining.cz. I acknowledge that withdrawing consent may limit the ability to use certain features of the web application.',
                                    )}
                                  </Typography>
                                </Flex>
                              }
                            >
                              <InfoIcon
                                color="primary"
                                sx={{ fontSize: '1.5rem' }}
                              />
                            </Tooltip>
                          </Flex>

                          <Button
                            id="qr-next"
                            type="submit"
                            size="large"
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                          >
                            {_t('next', 'Next')}
                          </Button>
                        </Flex>
                      </FormikForm>
                    )}
                  </Formik>
                </Flex>
              )}

              {wizzard.step === 'email' && (
                <Flex flexDirection="column" gap={3} alignItems="center">
                  {_t(
                    'enterEmail',
                    'Enter email where certificate will be sent',
                  ) && (
                    <Typography>
                      {_t(
                        'enterEmail',
                        'Enter email where certificate will be sent',
                      )}
                    </Typography>
                  )}
                  <img
                    src={certificate}
                    alt="Certificate"
                    style={{ width: '20ch' }}
                  />
                  <Formik<EmailForm>
                    initialValues={wizzard.emailForm}
                    onSubmit={handleEmailSubmit}
                    validationSchema={getEmailStepValidationSchema(_t)}
                  >
                    {({ isSubmitting, isValid, touched, errors }) => (
                      <FormikForm sx={{ margin: 0 }}>
                        <Flex flexDirection="column" gap={3}>
                          <Flex flexDirection="column" gap={1}>
                            <Typography textAlign="center">
                              #{_t('hashtag', 'trénujizáchranuživota')}
                            </Typography>
                            <FormikTextField
                              fullWidth
                              name="email"
                              variant="outlined"
                              label={_t('email', 'E-mail')}
                            />
                          </Flex>
                          {/*For some reason formik takes first link or button as submit even if it does not have type submit, so reverse it*/}
                          <Flex flexDirection="column-reverse" gap={3}>
                            <Button
                              id="qr-next"
                              type="submit"
                              size="large"
                              color="primary"
                              variant="contained"
                              disabled={isSubmitting}
                            >
                              {hasMultipleScenes
                                ? _t('emailNextToSelect', 'K výběru scény')
                                : _t('emailNext', 'K poslednímu kroku')}
                            </Button>

                            {_t('skip', 'Skip for now').trim() && (
                              <Link
                                component="button"
                                id="qr-skip"
                                onClick={handleEmailskip}
                                color="grey.500"
                              >
                                {_t('skip', 'Skip for now')}
                              </Link>
                            )}
                          </Flex>
                        </Flex>
                      </FormikForm>
                    )}
                  </Formik>
                </Flex>
              )}

              {wizzard.step === 'scene' && (
                <Flex flexDirection="column" gap={3} alignItems="center" pb={1}>
                  <Typography>
                    {_t('selectToLaunch', 'Select what app to launch')}
                  </Typography>
                  {qrScenes?.map((qrs) => (
                    <QrSceneCard
                      onClick={handleSceneSelect(qrs.id)}
                      selected={qrs.id === wizzard.qrSceneId}
                      scene={qrs.scene}
                      key={qrs.id}
                      id={qrs.id}
                    />
                  ))}
                  <Button
                    id="qr-select"
                    size="large"
                    color="primary"
                    variant="contained"
                    disabled={!wizzard.qrSceneId}
                    onClick={handleSceneSelected}
                  >
                    {_t('sceneNext', 'Select')}
                  </Button>
                </Flex>
              )}

              {wizzard.step === 'launch' && (
                <Flex flexDirection="column" gap={3} alignItems="center" pb={1}>
                  <Typography>
                    {_t(
                      'getReadyToLaunch',
                      'Launch training app in VR headset',
                    )}
                  </Typography>
                  <img src={cprAid} alt="CPR" style={{ width: '30ch' }} />
                  <Button
                    id="qr-launch"
                    size="large"
                    color="primary"
                    variant="contained"
                    disabled={launching}
                    onClick={handleLaunchClick}
                  >
                    {_t('launch', 'Launch')}
                  </Button>
                </Flex>
              )}

              {wizzard.step === 'wait' && (
                <Flex flexDirection="column" gap={3} alignItems="center">
                  <Typography>
                    {_t('waitForLaunch', 'Wait before putting on VR device')}
                  </Typography>
                  <img src={waitImg} alt="CPR" style={{ width: '30ch' }} />
                </Flex>
              )}

              {wizzard.step === 'done' && (
                <Flex flexDirection="column" gap={3} alignItems="center">
                  <Typography>
                    {_t('putHeadsetOn', 'Put on VR headset app is launching')}
                  </Typography>
                  <img
                    src={startImg}
                    alt="CPR"
                    style={{ width: '25ch', marginBottom: '1rem' }}
                  />
                </Flex>
              )}
            </CardContent>
          </Card>
        </Flex>
      </Flex>
    </ClientLayout>
  );
};

const getNameStepValidationSchema = (t: TFunction) =>
  Yup.object({
    firstName: Yup.string()
      .typeError(t('firstNameRequired', 'First name is required'))
      .required(t('firstNameRequired', 'First name is required')),
    lastName: Yup.string()
      .typeError(t('lastNameRequired', 'Last name is required'))
      .required(t('lastNameRequired', 'Last name is required')),
    birthYear: Yup.number()
      .typeError(t('birthYearRequired', 'Year of birth is required'))
      .required(t('birthYearRequired', 'Year of birth is required'))
      .min(
        new Date().getFullYear() - 100,
        t('birthYearRequired', 'Year of birth is required'),
      )
      .max(
        new Date().getFullYear(),
        t('birthYearRequired', 'Year of birth is required'),
      ),

    gdpr: Yup.bool()
      .typeError(t('gdprRequired', 'GDPR agreement is required'))
      .required(t('gdprRequired', 'GDPR agreement is required'))
      .oneOf([true], t('gdprRequired', 'GDPR agreement is required')),
  });

const getEmailStepValidationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string()
      .typeError(t('emailRequired', 'Email is required'))
      .email(t('emailInvalid', 'Email address is invalid'))
      .required(t('emailRequired', 'Email is required')),
  });

export default LaunchCompanyQrPage;
